import * as React from "react"

export default function() {
    return <>
              <div className="center-section">
                <div className="center-padding">
                  <div className="categories-box">
                    <div className="category">
                      <div className="category-padding">
                        <h1>User Guide</h1>
                        <ul className="list-info">
                          <li><a href="#">Getting Started</a></li>
                          <li><a href="#">Querying</a></li>
                          <li><a href="#">Visualizations</a></li>
                          <li><a href="#">Dashboards</a></li>
                          <li><a href="#">Alerts</a></li>
                          <li><a href="#">User, Groups, & Permissions</a></li>
                          <li><a href="#">Integrations and API</a></li>
                        </ul>
                        <h1>F.A.Q.</h1>
                        <ul className="list-info">
                          <li><a href="#">General</a></li>
                          <li><a href="#">Troubleshooting</a></li>
                          <li><a href="#">Hosted Redash</a></li>
                          <li><a href="#">Databricks</a></li>
                        </ul>
                      </div>
                    </div>
                  <div className="category">
                    <div className="category-padding">
                      <h1>Data Sources</h1>
                      <ul className="list-info">
                        <li><a href="#">Setup & Querying</a></li>
                        <li><a href="#">Supported Data Sources</a></li>
                      </ul>
                      <h1>Open Source & Self Hosted</h1>
                      <ul className="list-info">
                      <li><a href="#">Setting up a Redash Instance</a></li>
                      <li><a href="#">Admin Guide</a></li>
                      <li><a href="#">Developer Guide</a></li>
                    </ul>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
           </>;
}
