import * as React from "react"

export default function() {
    return <div className="search-box">
              <form action="#">
                <h1>Knowladge Base</h1>
                <input placeholder="Search for ..." type="text"/>
                <button className="button" type="submit">Search</button>
              </form>
           </div>;
}
