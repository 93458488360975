import * as React from "react"

import Header from '../componets/Template/Header';
import HeaderPage from '../componets/MainPage/Header';
import Search from '../componets/HelpPage/Search';
import Categories from '../componets/HelpPage/Categories';
import Footer from '../componets/HelpPage/Footer';

import '../assets/sass/main.scss'

// markup
const IndexPage = () => {
  return (
    <main>
      <Header title={'Data reporter Help'} />
      <header className="header-main-page help-page">
        <div className="center-section">
          <div className="center-padding">
            <HeaderPage />
            <Search />
          </div>
        </div>
      </header>
      <Categories />
      <Footer />
    </main>
  )
}

export default IndexPage
