import * as React from "react";
import FooterBottom from "../MainPage/FooterBottom";
export default function() {
    return <footer>
              <div className="footer-dark">
                <div className="clear"></div>
                <div className="center-section footer-feedback">
                  <div className="center-padding">
                    <div className="request-box">
                      <article>
                        Datareporter gives you a feature-complete size-limited Cloud Cluster,
                        deployed into your AWS account and managed by DataReporter.
                      </article>
                      <div className="control-box">
                        <div className="left is-desktop">
                          <a href="#" className="button">Request a Free Demo</a>
                        </div>
                        <div className="left text-info">
                          <span className="red">*</span> You control the infrastructure and data in your AWS VPC.
                        </div>
                        <div className="left is-mobile">
                          <a href="#" className="button">Request a Free Demo</a>
                        </div>
                      </div>
                    </div>
                    <FooterBottom />
                  </div>
                </div>
              </div>
           </footer>;
}
